// @import "../node_modules/bootstrap/scss/bootstrap";

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Include custom variable default overrides here
@import "./styles/theme.scss";

// Optional
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/code";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/input-group";
@import "../node_modules/bootstrap/scss/custom-forms";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/jumbotron";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/print";

@font-face {
  font-family: "VCR";
  src: local("VCR"), url(./assets/fonts/VCR_OSD_MONO.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Barlow";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(./assets/img/blue-gradient.png); */
  background-color: #030309 !important;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
  color: #fff;
}

body :not(input):not(textarea):not([contenteditable="true"]) {
  user-select: auto !important;
  -webkit-user-select: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

$placeholder-color: rgba(255, 255, 255, 0.64);

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $placeholder-color;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $placeholder-color;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $placeholder-color;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $placeholder-color;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholder-color;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: $placeholder-color;
}

@media (max-width: 480px) {
  .container {
    padding-left: 18px;
    padding-right: 18px;
  }
}

.modal-backdrop.show {
  opacity: 0;
}

/* Blue Swan Common Elements */

.font-weight-600 {
  font-weight: 600;
}
/* Tags */

.vault-tag {
  color: #5f85db;
  background: rgba(95, 133, 219, 0.04);
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 5px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

/* Tags */

.vault-detail-heading {
  color: #b3b6bc;
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
}

.vault-detail-text {
  font-weight: 600;
  font-size: 20px;
  line-height: 42px;
  text-transform: uppercase;

  color: #ffffff;
}

/* New Styles */
.navbar {
  font-weight: 600 !important;
}
.gradient-bg {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
  &.blue-gradient {
    background-image: url(./assets/img/blue-gradient.png);
  }
  &.purple-gradient {
    background-image: url(./assets/img/purple-gradient.png);
  }
  &.green-gradient {
    background-image: url(./assets/img/green-gradient.png);
  }
}

/* Components */
.tag {
  display: inline-block;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 5px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  &.vault {
    color: #5f85db;
    background: rgba(95, 133, 219, 0.04);
    font-size: 12px;
    line-height: 16px;
  }
  &.strategy {
    color: #bdf7ff;
    background: rgba(189, 247, 255, 0.04);
    font-size: 12px;
    line-height: 16px;
  }
  &.investment {
    background: rgba(255, 255, 255, 0.04);
    font-size: 12px;
    line-height: 16px;
    color: white;
  }
}

.box-grey-copy {
  display: block;
  background: rgba(189, 247, 255, 0.04);
  border-radius: 4px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: #bdf7ff;
  text-align: center;
  padding: 10px 20px;
}

.bs-btn {
  border: 0;
  padding: 15px 20px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: block;

  border-radius: 4px;
  text-transform: uppercase;

  &.blue {
    background: rgba(95, 133, 219, 0.32);
    color: #bacfff;
  }
  &.light-blue {
    background: rgba(55, 117, 255, 0.32);
    color: #3775ff;
  }
  &.purple {
    background: rgba(216, 167, 255, 0.32);
    color: #d8a7ff;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}

.bs-tab-btn {
  display: inline-block;
  margin-right: 10px;
  a,
  button {
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    text-decoration: none;
    border: 0;
    background-color: transparent;
  }
  .highlighted {
    display: none;
  }
  &:hover,
  &.active {
    .highlighted {
      /* width: 30%; */
      width: 30px;
      text-transform: uppercase;
      color: white;
      display: block;
      height: 5px;
      background-color: white;
      margin: 0 auto;
      margin-top: 10px;
      box-shadow: 0px -4px 32px #ffffff;
      border-radius: 4px 4px 0px 0px;
    }
  }
}
/* Components */

p {
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: white;
  a {
    color: white;
  }
}
/* Section Heading */

.section-heading {
  .heading {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 80px;
    letter-spacing: -0.04em;
    color: #fff;
  }
}

.box {
  &.active {
    box-shadow: 0px 0px 120px rgba(95, 133, 219, 0.4);
  }
  border: 1px solid rgba(95, 133, 219, 0.24);
  &.bg {
    background: rgba(95, 133, 219, 0.08);
  }
  &.radius {
    border-radius: 8px;
  }
  &.box-clean {
    border: 0;
  }
  &.box-items {
    &.box-clean {
      border: 0;
    }
    .box-item {
      border-right: 1px solid rgba(95, 133, 219, 0.24);
      &:last-child {
        border-right: 0;
      }
      h4 {
        small {
          font-size: 10px;
          display: inline-block;
          margin: 0 5px;
          text-transform: lowercase;
        }
      }
      h6 {
        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
    .box-border-top {
      border-top: 1px solid rgba(95, 133, 219, 0.24);
    }
  }
  .box-heading {
    text-align: center;
    font-size: 12px;
    line-height: 16px;
  }
}

.dark-box {
  background: rgba(189, 247, 255, 0.04);
  border-radius: 4px;
  color: white;
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 14px;
  }
  &.font-green {
    color: #bdf7ff;
  }
}

.progress-bar-fake {
  height: 5px;
  width: 90%;
  background: white;
  border-radius: 4px;
  margin: 0 auto;
}

footer {
  .footer-line {
    background: linear-gradient(
      90deg,
      rgba(95, 133, 219, 0) 0%,
      rgba(95, 133, 219, 0.24) 50.93%,
      rgba(95, 133, 219, 0) 100%
    );
    height: 1px;
    width: 100%;
  }
  h5 {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;

    color: #ffffff;
  }
  a {
    font-family: "Barlow";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #ffffff;
    &:hover {
      text-decoration: none;
      color: white;
      opacity: 0.7;
    }
  }
  .social-items {
    img {
      width: 30px;
    }
  }
}

.filter-button {
  color: #bacfff;
  background: rgba(95, 133, 219, 0.08);
  border-radius: 4px;
  border: 0;
  margin: 0px 5px;
  padding: 5px 10px;
  font-size: 14px;
  &.active {
    background: rgba(95, 133, 219, 0.24);
  }
}

.filterElement {
  background: rgba(95, 133, 219, 0.04);
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  margin: 10px 0;

  font-family: Barlow;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  color: #bacfff;
  &.green {
    color: #bdf7ff;
  }
  .colorBox {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
}
.vault-analytics-grid {
  h5 {
    font-size: 16px;
  }
}
.analytics-row {
  h6 {
    color: #b3b6bc;
    font-size: 14px;
  }
  h5 {
    font-size: 16px;
  }
}

.portfolio-analytics {
  .item-col {
    background: rgba(95, 133, 219, 0.04);
    border-right: 1px solid rgba(95, 133, 219, 0.24);
    padding: 10px;
    &:last-child {
      border-right: 0;
    }
    h6 {
      font-size: 14px;
      text-align: center;
    }
    .item-content {
      background: rgba(95, 133, 219, 0.04);
      border-radius: 4px;
    }
  }
}

.line-separator {
  &.fixed-margin {
    margin-top: -10px;
  }
  background: linear-gradient(
    90deg,
    rgba(95, 133, 219, 0) 0%,
    rgba(95, 133, 219, 0.24) 50.93%,
    rgba(95, 133, 219, 0) 100%
  );
  height: 1px;
  width: 100%;
}

.activity-table {
  border-collapse: separate;
  border-spacing: 0 1rem;
  thead {
    tr {
      background: none;
      border: 0;
      th {
        border: 0;
        color: #b3b6bc;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  tbody {
    border: 0;
    tr {
      border: 0;
      background: rgba(95, 133, 219, 0.04);
      td {
        text-transform: uppercase;
        color: white;
        border: 0;
        font-weight: 600;
        text-align: center;
        margin: 10px 0;
      }
    }
  }
}
.link-disabled {
  opacity: 0.4;
  pointer-events: none;
}

input.form-control.depositInput {
  background: rgba(95, 133, 219, 0.16) !important;
  border-radius: 4px;
  text-align: right;
  font-size: 20px;
  line-height: 36px;
  padding: 0 140px 0 100px;
  color: #bacfff !important;
  font-weight: 600 !important;
}
.maxBtnBlue {
  left: 30px !important;
  right: unset !important;
  background: rgba(95, 133, 219, 0.32) !important;
  color: #bacfff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 15px !important;
  height: auto !important;
}
.form-asset-float {
  position: absolute;
  right: 20px;
  top: 40%;
  color: #bacfff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}
button.blueswanActionButton {
  background: rgba(95, 133, 219, 0.32);
  opacity: 0.6;
  border-radius: 4px;
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  text-transform: uppercase;
  padding: 14px 0 !important;
  color: #bacfff;
}
.bg-none {
  background: none !important;
}
.asset-exposure {
  margin-top: -5px;
}

.btn-analytics {
  font-family: Barlow, sans-serif;
  display: inline-block;

  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  background: #3e73c414;

  color: #3e73c4;
  box-shadow: 8px 16px 64px #3e73c414;
  width: 100%;

  a {
    color: #3e73c4;
    font-weight: 400;
  }
  &:hover {
    background: #3e73c429;
    color: #3e73c4;
  }
}

.bs-table {
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
  border-spacing: 0px;

  margin: 20px auto;
  width: 100%;
  border: 1px solid #5f85db3d;

  background: rgba(95, 133, 219, 0.08);
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th{
  border-left: 1px solid #5f85db3d;
  text-align: center;
  font-size: 12px;
  padding:10px 0;
  vertical-align: middle;
}
td {
  border-left: 1px solid #5f85db3d;
  text-align: center;
  font-size: 14px;
  padding:10px 0;
  vertical-align: middle;
}
td {
  border-top: 1px solid #5f85db3d;
}
thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 4px 0 0 0;
}
thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
}

.portfolio-row-fixed-size{
  @media screen and (min-width:768px) {
    min-width: 150px;
  }
}
.menu-fixed{
  position: absolute;
  width: 100%;
}

.modal-content{
  a{
    text-decoration: none;
  }
}

/* New Styles */
