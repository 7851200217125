@import "_animations.scss";
html {
  scroll-behavior: smooth;
}
@mixin animated-text($font-size: 2.5rem, $line-size: 3rem) {
  h2 {
    font-size: $font-size;
  }
  .content {
    overflow: hidden;
    width: 100%;
    font-family: "Lato", sans-serif;
    font-size: $font-size;
    line-height: $line-size;
    color: $i-black-color;

    &__container {
      font-weight: 600;
      overflow: hidden;
      height: ($line-size + $line-size / 8);

      &__text {
        display: inline;
        float: left;
        margin: 0;
      }

      &__list {
        color: $i-blue-color;
        margin-top: 0;
        padding-left: 0px;
        text-align: left;
        list-style: none;

        -webkit-animation-name: change;
        -webkit-animation-duration: 10s;
        -webkit-animation-iteration-count: infinite;
        animation-name: change;
        animation-duration: 10s;
        animation-iteration-count: infinite;

        &__item {
          line-height: $line-size;
          margin: 0;
        }
      }
    }
  }
}

/* Global Variables */
$font-barlow: "Barlow", sans-serif;
$font-lato: "Lato", sans-serif;
$i-blue-color: #5f85db;
$i-black-color: #353941;
$i-red-color: #fb6363;
$i-grey-color: #7a7a7a;
$i-light-blue-color: #bacbf0;
.institutional-footer{
  background-color: $i-black-color;
  padding-top:0!important;
  .footer-line{
    background:none;
    padding-top:1rem;
  }
}
.institutional-header {
  background-color: white !important;
  border: 0 !important;
  .btn-connect-wallet {
    & > div > div > div {
      background: $i-blue-color;
      span{color:white!important;}
    }
  }
  .bs-dropdown-header {
    div {
      background-color: white !important;
    }
  }
  .nav-links-wrapper {
    margin: 0 auto !important;
    div,
    span {
      color: $i-black-color !important;
    }
    a {
      div {
        color: $i-black-color !important;
        span {
          color: $i-black-color !important;
        }
      }
    }
  }
}
.institutional-wrapper {
  font-family: $font-barlow;
  font-weight: 400;
  background: #f7f9ff;
  color: $i-black-color;

  /* General Styles */
  .container-fluid {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .blue-text {
    color: $i-blue-color !important;
  }
  .white-text {
    color: #fff;
  }
  .red-text {
    color: $i-red-color;
  }
  .bs-text-underlined {
    background-image: url("../../assets/img/institutional/underline.svg");
    background-position: bottom left;
    background-size: auto;
    background-repeat: no-repeat;
  }
  .bs-text-underlined-black {
    background-image: url("../../assets/img/institutional/underline-black.svg");
    background-position: bottom -7px left -75px;
    background-size: auto;
    background-repeat: repeat-x;
  }
  .bs-text-underlined-white {
    background-image: url("../../assets/img/institutional/underline-white.svg");
    background-position: bottom -7px left -75px;
    background-size: auto;
    background-repeat: repeat-x;
  }
  h1 {
    font-weight: 700;
    @media screen and (max-width: 769px) {
      font-size: 2rem;
    }
  }
  h2 {
    font-weight: 700;
  }
  p {
    color: $i-black-color;
    font-size: 16px;
  }

  .bs-text-underline {
    text-decoration: underline;
  }
  /* General Styles */

  .i-rounded-btn {
    background: $i-blue-color;
    border-radius: 60px;
    color: white;
    text-transform: uppercase;
    padding: 10px 20px;
    border:0;
    font-weight: 600;
    &:hover{
      text-decoration: none;
      opacity: .6;
    }
    &.i-white-btn{
      background: white;
      color: $i-blue-color;
    }
  }

  .bs-i-hero {
    display: flex;
    align-items: center;
    background: url("../../assets/img/institutional/bg-hero.png");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 65%;
    @media screen and (min-width: 769px) {
      min-height: 70vh;
    }
    p {
      color: $i-grey-color;
    }
  }

  .bs-i-light-section {
    background-color: #f1f5ff;
  }

  .bs-i-boxes-section {
    .bs-dashed-wrapper {
      border: 2px dashed rgba(95, 133, 219, 0.6);
      border-radius: 15px;
      padding: 2rem;
    }
    .bs-dashed-container {
      border: 2px dashed #5f85db;
      border-radius: 15px;
    }
    .col-md-4 {
    }
  }

  .i-contact-btn{
    @media screen and (max-width: 769px) {
      width: 100%;
    }
  }
  .bs-i-blue-section {
    background: url("../../assets/img/institutional/top-left-bg.png") top left
      no-repeat;
    background-size: 150px;
    background-color: $i-blue-color;
    .right-img {
      @media screen and (min-width: 769px) {
        padding-left: 14rem;
      }
    }
    .steps-row {
      .number-step {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 40px;
        text-align: center;

        color: #a8cfd4;
      }
      h3 {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;

        color: #ffffff;
      }
      ul {
        li {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .bs-i-alternative-blocks {
    .bs-i-box {
      &.bg-circles {
        background: url(../../assets/img/institutional/bg-circles.png);
        background-size: 60px;
        background-repeat: no-repeat;
        background-position: 20px 100px;
      }
      &.bg-circles-right {
        background: url(../../assets/img/institutional/bg-circles.png);
        background-size: 60px;
        background-repeat: no-repeat;
        background-position: right 30px top;
      }
      &.bg-circles-right-red {
        background: url(../../assets/img/institutional/bg-circles.png);
        background-size: 60px;
        background-repeat: no-repeat;
        background-position: right 30px top 80px;
      }
      width: 100%;
      max-width: 100%;

      h2 {
        font-family: "Barlow";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        text-transform: capitalize;
        color: #353941;
        margin-bottom: 1rem;
      }
      p {
        font-family: "Lato", sans-serif;

        border-left: 1px solid #5f85db;
        &.border-red {
          border-left: 1px solid #fb6363;
        }
        padding: 0 0.5rem !important;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.01em;
        color: rgba(53, 57, 65, 0.6);
      }
    }
  }

  .bs-i-bullet-section {
  }

  .bs-i-contact {
    background: url("../../assets/img/institutional/i-bg-contact.png");
    background-repeat: no-repeat;
    label {
      color: white;
      margin-bottom: 2px;
      font-size: 14px;
      padding-left: 8px;
    }
    input,
    textarea {
      background: transparent;
      color:white;
    }
    .col-md-6,
    .col-12 {
      margin-bottom: 0.5rem;
    }
  }
}

/* Animated Text */
.main-animated-section {
  @include animated-text(2.5rem, 3rem);
}
.secondary-animated-section {
  @include animated-text(1.5rem, 2rem);
  @media screen and (min-width: 769px) {
    @include animated-text(2rem, 2.5rem);
  }
}

/* Animated Text */
